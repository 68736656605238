import {HTTP} from "../../helpers/axios";
// src/thunks.ts
import { Action } from 'redux'
import {RootState} from '../../reducers'
import { ThunkAction } from 'redux-thunk'
import { serverRoutes} from "../../api/serverRoutes";
import FormData from "form-data";

export const uploadNewSimcard = (data:{dateTopUp:string,excel:File|null},onUploadProgress:(data:any)=>void,callback:(error:boolean)=>void):ThunkAction<void, RootState, unknown, Action<string>> => async (dispatch,getState) => {

    console.log(data);
    try {
        let formData = new FormData();
        // @ts-ignore
        formData.append("excel", data.excel);
        formData.append('dateTopUp', data.dateTopUp);


        const response=  await   HTTP(getState,dispatch).post(serverRoutes.uploadNewSims,formData,{
            onUploadProgress:(evt)=>{
                let data = Math.min(100, parseInt(String(100.0 * evt.loaded / evt.total)));
                console.log(data)
            },
            headers:{
                "mimeType": "multipart/form-data",
                "contentType": false,
            }
        });

        let error=false;
        callback(error);
    }catch (e) {
        console.log(e);
        let error=true;
        callback(error);
    }


};

